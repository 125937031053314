import React, { useMemo } from "react";

import { Button } from "primereact/button";
import SingleUploadImage from "components/upload/single.upload";
import { IRestaurant } from "app/interfaces/restaurants";
import { ICategory } from "app/interfaces/categories";
import { useGetRefreshRestaurantMutation, useUploadLogotypeMutation } from "app/services/restaurants";

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const MenuEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [uploadLogotype] = useUploadLogotypeMutation();
  const [ getRestaurantById ] = useGetRefreshRestaurantMutation();
  
  const upload = (file: any) => {
    uploadLogotype({ restaurantId: restaurant.id, file})
      .unwrap().then( () => getRestaurantById({ id: restaurant.id }) )
  }

  const isHookah = useMemo(() => {
    if(!restaurant) return false;
    return restaurant.categories.map( 
      (category: ICategory) => category.name
    ).includes('Кальянная')
  }, [restaurant])

  return (
    <div className="col-12 p-0">
      <div className="flex justify-content-center flex-wrap col-12">
        <SingleUploadImage 
          label={"Загрузить логотип"} 
          onSelectFIle={upload}
          currentImg={restaurant ? restaurant.logo : undefined}
        />
        <div className="col-12 text-center">
          <h4 className="m-1">{restaurant && restaurant.name} <br /> ( {restaurant && `г. ${restaurant.city}, ${restaurant.address}`} )</h4>
        </div>
      </div>
      <div className="col-12 p-0">
        <Button label="Основная информация" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('main')} />
        <Button label="Управляющие" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('managers')} />
        <Button label="Адрес(координаты)" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('address')} />
        <Button label="Контакты" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('contacts')} />
        <Button label="Галерея" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('gallery')} />
        <Button label="Социальные сети" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('social')} />
        <Button label="Кухня" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('kitchen')} />
        
        {isHookah && 
          <Button label="Кальян" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('hookah')} />
        }
        
        <Button label="Часы работы" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('hours')} />
        <Button label="Хештеги" className="p-button-raised p-button-info p-button-text col-12 mb-2" onClick={() => setPage('hashtags')} />
      </div>
    </div>
  );
}

export default MenuEdit;
