import React, { useState } from 'react';
import { ExpansionIcon, ExpansionItem } from "components/expansion";
import DishesMenu from './dishes.menu';
import { ICategory } from 'app/interfaces/categories';
import { IDishes } from 'app/interfaces/restaurants';

interface IProps {
  category: ICategory | null;
  items: IDishes[];
  setItem: (dish: IDishes) => void;
}

const CategoryMenu: React.FC<IProps> = (props: any) => {
  const [active, setActive] = useState(false);
  const {category, items, setItem} = props;
  
  return (
    <div className="col-12 mt-3" key={category.id}>
      <div className="col-12 p-0 flex align-items-center" onClick={()=> setActive(!active)} style={{cursor: 'pointer'}}>
        <h4 className="dishes-category-name mr-2 mt-2 mb-2">{category.length ? category[0].name : "Прочее ..."}</h4>
        <ExpansionIcon
          active={active}
          setActive={()=>{}}
          className="align-self-end pt-2"
        />
      </div>

      <ExpansionItem
        className="col-12 order-6 p-0"
        active={active}
        withLine
      >
        <DishesMenu items={items} setItem={setItem} />
      </ExpansionItem>
      
    </div>
  );
};

export default CategoryMenu;