import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { Field, Form, useForm } from "react-final-form";
import { composeValidators, fullAddressValidator, requiredValidator } from "components/form/validators";
import { KladrField, MaskField, TextField } from "components/form/fields";
import { useUpdateRestaurantAddressMutation } from "app/services/restaurants";

const formatResponse = (data: any) => {
  return {
    restaurantId: data.id,
    city: {
      name: data.newCity?.data.city,
      region: data.newCity?.data.region_kladr_id
    },
    address: `${data.newCity.value.replace(`${data.newCity?.data.city_with_type}, `, '')}`,
    metro: data.addInfo?.metro ? data.addInfo.metro : null,
    entry: data.addInfo?.addressEntry ? data.addInfo.addressEntry : null,
    long: data.long,
    lat: data.lat
  }
}

interface IAddressProps {
  restaurant: IRestaurant;
}

const AddressFormEdit: React.FC<IAddressProps> = (props: IAddressProps) => {
  const { restaurant } = props;
  const [ defaultAddress, setAddress ] = useState("");
  const form = useForm ();

  useEffect(()=> {
    setAddress(`г. ${restaurant?.city}, ${restaurant?.address}`)
  }, [restaurant])

  const changeAddress = (data: any) => {
    if(data && data.data) {
      form.change('long', data.data.geo_lon)
      form.change('lat', data.data.geo_lat)
      form.change('newCity', data)
    }
  }

  return (
    <div className="flex flex-wrap">
      <div className="col-12 p-0 flex flex-wrap">
        <div className="col-12 pb-0">
          <div className="pt-2">
            <Field
              validate={composeValidators(requiredValidator, fullAddressValidator)}
              name={defaultAddress ? "newCity" : "city"}
              label="Адрес:"
              render={KladrField}
              settings={{
                defaultQuery: defaultAddress,
                selectOnBlur: true,
                placeholder: "Введите адрес заведения",
                autoload: true,
                onChange: changeAddress
              }}
            />
          </div>

          <div className="p-col-12 p-md-6">
            <Field
              name="addInfo.metro" label="Ближайшая станция метро:" render={TextField}
            />
          </div>

          <div className="pt-2">
            <Field
              name="addInfo.addressEntry" label="Вход:" render={TextField}
            />
          </div>

          <div className="col-12 md:col-6 p-0 md:pr-2 md:pl-2 pt-2">
            <Field
              name="lat"
              label="Широта:"
              render={MaskField}
              settings={{
                  placeholder: "Укажите широту",
                  mask: "00.000000",
                  disabled: true
                }}
              />
          </div>

          <div className="col-12 md:col-6 p-0 md:pr-2 md:pl-2 pt-2">
            <Field
              name="long"
              label="Долгота:"
              render={MaskField}
              settings={{
                  placeholder: "Укажите долготу",
                  mask: "00.000000",
                  disabled: true
                }}
              />
          </div>
        </div>
      </div>

    </div>
  );
}

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const AddressEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ updateRestaurant ] = useUpdateRestaurantAddressMutation();

  const onSubmit = (values: any) => {
    updateRestaurant( formatResponse(values) ).unwrap()
      .then( () => setPage('menu') )
  };

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Адрес (Координаты) </h4>

        <Form
          onSubmit={onSubmit}
          initialValues={restaurant}
          render={({ handleSubmit }) => (
            
              <form onSubmit={handleSubmit} >
                
                <AddressFormEdit restaurant={restaurant}/>
              
                <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                  
                  <div className="col-12 md:col-6 xl:col-3">
                    <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                  </div>
                </div>

              </form>
          )}
        />
      </div>
    </div>
  );
}

export default AddressEdit;

