import React, { useEffect, useMemo, useState } from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { SelectField } from "components/form/fields";
import { useGetOwnersQuery } from "app/services/users";
import { useUpdateRestaurantManagersMutation } from "app/services/restaurants";

const formatResponse = (data: any) => {
  return {
    restaurantId: data.id,
    ownerId: data.ownerId?.accountId,
    managerId: data?.managerId ? data.managerId.accountId : null
  }
}

const formatInitial = (data: any, users: any) => {
  let owner = data.ownerId ? users.filter( (i: any) => i.accountId === data.ownerId)?.[0] : null;
  let manager = data.managerId ? users.filter( (i: any) => i.accountId === data.managerId)?.[0] : null;
  
  let result = {
    id: data.id,
    ownerId: owner,
    managerId: manager,
  }
  
  if(result.ownerId) {
    const surname = owner?.surname ? owner.surname : '';
    result.ownerId = Object.assign({}, result.ownerId, {fullName:`${surname} ${owner.name}`})
  }

  if(result.managerId) {
    const surname = manager?.surname ? manager.surname : '';
    result.managerId = Object.assign({}, result.managerId, {fullName:`${surname} ${manager.name}`})
  }

  return result;
}

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const ManagersEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ initialData, setData ] = useState<any>(null)
  
  const {data} = useGetOwnersQuery(null);
  const [updateRestaurant] = useUpdateRestaurantManagersMutation()

  useEffect( () => {
    if(data) setData(formatInitial(restaurant, data))
  }, [restaurant, data] )

  const ownersMap = useMemo(() => {
    if(!data) return [];
    const owners = data.filter( (user: any) => user.role === 'owner').map( (owner: any) => {
      const surname = owner.surname ? owner.surname : '';
      return { ...owner, fullName: `${surname} ${owner.name}`};
    } )

    return owners;
  }, [data])
  
  const managerMap = useMemo(() => {
    if(!data) return [];
    const owners = data.filter( (user: any) => user.role === 'manager').map( (owner: any) => {
      const surname = owner.surname ? owner.surname : '';
      return { ...owner, fullName: `${surname} ${owner.name}`};
    } )

    return owners;
  }, [data])

  const onSubmit = (values: any) => {
    updateRestaurant(formatResponse(values)).unwrap()
      .then( () => setPage('menu') )
  };

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Управляющие </h4>

          <Form
            onSubmit={onSubmit}
            initialValues={initialData}
            render={({ handleSubmit }) => (
            
              <form onSubmit={handleSubmit} >

                <div className="mt-2 mb-2">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="ownerId" label="Владелец:"
                    render={SelectField}
                    settings={{
                      options: ownersMap,
                      optionLabel: "fullName",
                      filter: true,
                      showClear: true, 
                      filterBy: "fullName",
                      placeholder: "Выберите владельца"
                    }}
                  />
                </div>
                
                <div className="mt-2 mb-2">
                  <Field
                    name="managerId" label="Управляющий:"
                    render={SelectField}
                    settings={{
                      options: managerMap,
                      optionLabel: "fullName",
                      filter: true,
                      showClear: true, 
                      filterBy: "fullName",
                      placeholder: "Выберите управляющего"
                    }}
                  />
                </div>

                <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                  
                  <div className="col-12 md:col-6 xl:col-3">
                    <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                  </div>
                </div>
              </form>
          )}
        />
      </div>
    </div>
  );
}

export default ManagersEdit;
