import React from "react";

import { Button } from "primereact/button";
import { IRestaurant } from "app/interfaces/restaurants";
import { Field, Form } from "react-final-form";
import { TextField } from "components/form/fields";
import { useUpdateRestaurantSocialMutation } from "app/services/restaurants";
import { urlValidator } from "components/form/validators";

const formatResponse = (data: any) => {
  return {
    restaurantId: data.id,
    facebook: data?.social?.facebook ? data.social.facebook : "",
    instagram: data?.social?.instagram ? data.social.instagram : "",
    telegram: data?.social?.telegram ? data.social.telegram : "",
    twitter: data?.social?.twitter ? data.social.twitter : "",
    vk: data?.social?.vk ? data.social.vk : "",
    youtube: data?.social?.youtube ? data.social.youtube : "",
  }
}

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const SocialEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ updateRestaurant ] = useUpdateRestaurantSocialMutation();

  const onSubmit = (values: any) => {
    updateRestaurant( formatResponse(values) ).unwrap()
      .then( () => setPage("menu") )
  };

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Социальные сети </h4>
        <Form
          onSubmit={onSubmit}
          initialValues={restaurant}
          render={({ handleSubmit }) => (
                
            <form onSubmit={handleSubmit} >
              <div className="col-12 p-0">
                <div className="card-mini p-mt-2">

                  <div className="col-12 flex flex-wrap">
                    <div className="col-12 md:col-6 p-1">
                      <Field
                        validate={urlValidator}
                        name="social.facebook" label="facebook:" render={TextField}
                        settings={{ placeholder: "https://www.facebook.com/" }}
                      />
                    </div>
                    <div className="col-12 md:col-6 p-1">
                      <Field
                        validate={urlValidator}
                        name="social.instagram" label="instagram:" render={TextField}
                        settings={{ placeholder: "https://www.instagram.com/" }}
                      />
                    </div>
                    <div className="col-12 md:col-6 p-1">
                      <Field
                        validate={urlValidator}
                        name="social.telegram" label="telegram:" render={TextField}
                        settings={{ placeholder: "https://tlgrm.ru/" }}
                      />
                    </div>
                    <div className="col-12 md:col-6 p-1">
                      <Field
                        validate={urlValidator}
                        name="social.twitter" label="twitter:" render={TextField}
                        settings={{ placeholder: "https://twitter.com/" }}
                      />
                    </div>
                    <div className="col-12 md:col-6 p-1">
                      <Field
                        validate={urlValidator}
                        name="social.vk" label="vk:" render={TextField}
                        settings={{ placeholder: "https://vk.com/" }}
                      />
                    </div>
                    <div className="col-12 md:col-6 p-1">
                      <Field
                        validate={urlValidator}
                        name="social.youtube" label="youtube:" render={TextField}
                        settings={{ placeholder: "https://www.youtube.com/" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
                    
              <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-end">
                <div className="col-12 md:col-6 xl:col-3 ">
                  <Button label="Сохранить" className="col-12 p-button-raised p-button-success "/>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default SocialEdit;
