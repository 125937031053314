

// import { ICategory } from "app/interfaces/categories";
import { useGetRestaurantsMutation, useUpdateRestaurantActionMutation, useUpdateRestaurantDeactiveMutation } from "app/services/restaurants";
import ActionsColumn from "components/table/action.column";
import ImageColumn from "components/table/image.column";
import { show } from "features/windows/slice";
import { useRestaurants } from "hooks/useRestaurants";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

interface IProps {
  setElementId: (id: string) => void;
}

export const TableRestaurants: React.FC<IProps> = (props: IProps) => {
  const { setElementId } = props;
  const dispatch = useDispatch();
  const { restaurants, filter } = useRestaurants()
  
  const [ getRestaurants, { isLoading } ] = useGetRestaurantsMutation()
  const [ actionRestaurant ] = useUpdateRestaurantActionMutation()
  const [ deactionRestaurant ] = useUpdateRestaurantDeactiveMutation()

  useEffect(()=> { getRestaurants(filter) }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleActive = (id: string) => actionRestaurant({restaurantId: id}).then( () =>  getRestaurants(filter));
  const handleDeactivate = (id: string) => deactionRestaurant({restaurantId: id}).then( () =>  getRestaurants(filter));
  const handleViewing = (id: string) => {
    setElementId(id)
    dispatch(show({type: 'restaurants', name: 'edit'}))
  };
        
  let menu = (item: any) => [
    {
      label: 'Просмотр заведения', 
      icon: 'pi pi-fw pi-pencil', 
      command:()=> handleViewing(item.id),
    },
    {
      label: item.status !== 'active' ? 'Активировать' : 'Деактивировать', 
      icon: item.status !== 'active' ? 'pi pi-fw pi-check-circle' : 'pi pi-fw pi-ban', 
      command:()=> item.status !== 'active' ? handleActive(item.id) : handleDeactivate(item.id),
    }
  ]
        
  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />
  const imageBody = (rowData: any) => <ImageColumn image={`${rowData.logo}`} />
        
  return (
    <DataTable value={restaurants} 
      scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
      responsiveLayout="stack" breakpoint="768px" 
      loading={isLoading}
    >
      <Column header="Заведение" body={imageBody}></Column>
      <Column field="name" header="Название"></Column>
      <Column field="telephone" header="Телефон"></Column>
      <Column field="status" header="Статус"></Column>
      <Column body={action} style={{ maxWidth: '5rem', float: "right" }}></Column>
    </DataTable>
  );
}