import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { TableRestaurants } from "features/restaurants/table.restaurant";
import { useGetRestaurantsMutation } from "app/services/restaurants";
import { useRestaurants } from "hooks/useRestaurants";
import AddRestaurantWin from "./windows/add.restaurant.win";
import { show } from "features/windows/slice";
import { useDispatch } from "react-redux";
import EditRestaurantWin from "./windows/edit.restaurant.win";

export const ListRestaurantsPage: React.FC = () => {
  const [ currentId, setCurrentId ] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const { filter } = useRestaurants()
  const [ getRestaurants ] = useGetRestaurantsMutation()

  const handleAdd = () => dispatch(show({type: 'restaurants', name: 'add'}));
  const handleRefresh = () => getRestaurants(filter);
  // const handleFilters = () => console.log('filters', true);

  return (
    <>

      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 208px)' }}>

        <div className="col-12 pl-0 flex flex-wrap align-items-baseline justify-content-between">
          <div className="flex flex-wrap">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success mr-1"/>
            <Button label="Обновить" onClick={handleRefresh} className="p-button-raised p-button-info p-button-text ml-1"/>
          </div>

          {/* <Button icon="pi pi-sliders-h" onClick={handleFilters} className="p-button-raised p-button-info p-button-text ml-1"/> */}
        </div>

        <div className="card">
          <TableRestaurants setElementId={setCurrentId} />
        </div>

      </ScrollPanel>

      <AddRestaurantWin setElementId={setCurrentId} />
      <EditRestaurantWin restaurantId={currentId}/>
    </>
  );
}

export default ListRestaurantsPage;