import { api } from './api'

export const kitchenApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDishesById: build.mutation<any, {id: string}>({
      query: (arg) => {
        return {
          url: `/restaurants/${arg.id}`,
          method: 'GET'
        };
      },
    }),
    createDishes: build.mutation<any, Partial<any>>({
      query: (credentials: any) => ({
        url: `/kitchen/dishes/restaurant/${credentials.restaurantId}`,
        method: 'POST',
        body: credentials,
      })
    }),
    updateDishes: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/kitchen/dishes/${credentials.id}`,
        method: 'PUT',
        body: credentials,
      })
    }),
    deleteDishes: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => ({
        url: `/kitchen/dishes/${credentials.dishesId}`,
        method: 'DELETE',
        body: credentials,
      })
    }),

    uploadPhoto: build.mutation<{}, Partial<any>>({
      query: (credentials: any) => {
        const fd = new FormData();
        fd.append('image', credentials.file, credentials.file.name)

        return {
          url: `/kitchen/dishes/${credentials.dishesId}/photo`, 
          method: 'POST',
          body: fd,
          credentials: 'include',
        }
      }
    }),
  }),
})

export const { 
  useGetDishesByIdMutation,
  useCreateDishesMutation,
  useUpdateDishesMutation,
  useDeleteDishesMutation,
  useUploadPhotoMutation,
} = kitchenApi;