import React, { useEffect, useState } from "react";

import { useGetRefreshRestaurantMutation } from "app/services/restaurants";
import { ProgressSpinner } from 'primereact/progressspinner';
import SwitchEdit from "./components/switch.edit";

interface IProps {
  restaurantId: string;
}

export const MenuEditRestaurant: React.FC<IProps> = (props: IProps) => {
  const { restaurantId } = props;
  const [ page, setPage ] = useState<string>('menu')
  const [ progress, setProgress ] = useState<boolean>(false)

  const [ getRestaurantById, { data, isLoading} ] = useGetRefreshRestaurantMutation();

  useEffect( () => { getRestaurantById({id:restaurantId}) }, [restaurantId, getRestaurantById])

  const handleChange = (page: string) => {
    setProgress(true);
    setPage(page)

    if(page === 'menu') getRestaurantById({id:restaurantId})
    setTimeout( () => setProgress(false), 600)
  }

  return (
    <>
      {progress || isLoading
        ? (
          <div className="col-12 p-0 flex align-items-center" style={{height: '100%'}}>
            <ProgressSpinner className="col-12 flex justify-content-center "/>
          </div>
        )
        : <SwitchEdit page={page} data={data} setPage={handleChange} />
      }
    </>
  );
}

export default MenuEditRestaurant;
