import React from 'react';
import { Divider } from 'primereact/divider';

import { Field } from 'react-final-form';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { IPhone } from 'app/interfaces/restaurants';

interface IProps {
  phones: IPhone[];
  setPhone: (e: any) => void;
}

const AddingPhones: React.FC<IProps> = (props: any) => {
  const {phones, setPhone} = props;
  const scheme = { phone: "", comment: "" } 
  const setPhoneValue = (e: any) => {
    const index = e.target.name;
    setPhone( (prev: IPhone[]) => {
      prev[index].phone = e.target.value;
      return prev;
    } )
  }
  const setPhoneComment = (e: any) => {
    const index = e.target.name;
    setPhone( (prev: IPhone[]) => {
      prev[index].comment = e.target.value;
      return prev;
    } )
  }

  const handleAdd = (e: any) => {
    e.preventDefault();

    setPhone( (prev: any) => {
      return [...prev, scheme]
    })
  }
  const handleRemove = (e: any, phone: IPhone) => {
    e.preventDefault();

    setPhone( (prev: any) => {
      const phones = prev.slice();
      const index = phones.indexOf(phone);
      if(index !== -1) phones.splice(index, 1);

      return phones;
    })
  }

  const renderItem = phones.map( (phone: IPhone, index: number) => (
    <div key={index} className="col-12 p-0 flex flex-wrap">
      <div className="col-12 md:col-6 pt-1 pb-1">
        <div className="custom-input-text" >
          <label>Номер телефона: </label>
          <InputMask value={phone.phone} name={`${index}`} onChange={setPhoneValue} mask="+79999999999" />
        </div>
      </div>
      <div className="col-12 md:col-6 flex align-items-center justify-content-between md:justify-content-start pt-1 pb-1">
        <div className="col-10 pt-0 pb-0 pl-0 pr-2">
          <Field name="phone.comment">
            {props => (
              <div className="custom-input-text" >
                <label>Комментарий: </label>
                <InputText name={`${index}`} value={phone.comment} onChange={(e) => {
                    props.input.onChange(e)
                    setPhoneComment(e)
                  }}
                />
              </div>
            )}
          </Field>
          
        </div>
        <Button 
          onClick={(e: any) => handleRemove(e, phone)} 
          icon="pi pi-trash" 
          className="p-button-rounded p-button-danger col-2 md:col-2"
        />
      </div>
    </div>
  ))

  return (
    <div className="col-12 p-0">
      <Divider align="left" type="dashed">
        <b>Дополнительные номера</b>
      </Divider>

      <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success ml-2"/>

      {renderItem}
    </div>
  );
};

export default AddingPhones;