import { api } from './api'

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    // getOwners: build.mutation<any, IFiltersRestaurants>({
    //   query: (arg) => {
    //     return {
    //       url: '/restaurants',
    //       method: 'GET',
    //       params: {filters: JSON.stringify(arg)},
    //     };
    //   },
    // }),
    getOwners: build.query({
      query: () => `/users/owners`,
    }),
  }),
})

export const { useGetOwnersQuery } = usersApi;